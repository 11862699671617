<template>
  <div :class="$style.container">
    <el-form
      :class="$style.form"
      label-position="left"
      label-width="15.6rem"
      :model="form"
      :rules="rules"
      ref="form"
    >
      <el-form-item label="Название" prop="name">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="Название цены в МС" prop="moyskladPriceName">
        <el-input v-model="form.moyskladPriceName"></el-input>
      </el-form-item>
      <el-form-item
        label="Название количества в МС"
        prop="moyskladQuantityName"
      >
        <el-input v-model="form.moyskladQuantityName"></el-input>
      </el-form-item>
      <el-form-item label="По умолчанию">
        <el-checkbox
          :class="$style.checkBox"
          v-model="form.isDefault"
        ></el-checkbox>
      </el-form-item>
    </el-form>
    <div :class="$style.buttonWraper">
      <el-button :class="$style.button" @click="submitForm()"
        >Создать</el-button
      >
    </div>
  </div>
</template>

<script>
import delivery from '@/delivery'
import notifications from '@/mixins/notifications'

export default {
  name: 'sellersPricesPolicyCreate',
  mixins: [notifications],
  data() {
    return {
      form: {
        name: '',
        moyskladPriceName: '',
        moyskladQuantityName: '',
        isDefault: false
      },
      rules: {
        name: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur'
          }
        ],
        moyskladPriceName: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur'
          }
        ],
        moyskladQuantityName: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    async submitForm() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true
          })
          const result = await delivery.AddwineCore.PricePolicyActions.create(
            this.form
          )
          loading.close()
          if (!result.error) {
            this.showNotification('Ценовая политика успешно создана', 'success')
            this.$router.push('/sellers/prices-policy')
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" module>
.container {
  margin-top: 2rem;
  padding: 0 2rem;
  .form {
    .checkBox {
      & > span > span {
        width: 1.8rem;
        height: 1.8rem;
        &:after {
          width: 0.5rem;
          height: 0.8rem;
          left: 30%;
          top: 0.3rem;
        }
      }
    }
  }
  .buttonWraper {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
